import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import { memo, useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import lang from 'src/lang/lang';
import { ExportDrawler, IconFont, ImportDrawler, ModalConfirm } from 'src/libraries';
import { AfterRouteMenus, BeforeRouteMenus, Routes } from 'src/routes';
import { AuthService } from 'src/services';
import { useTenant, useUser } from 'src/stores';
import { ISetting, LayoutType } from 'src/types';
import { FUNCS } from 'src/utils';
import ChatWindow from './components/ChatWindow';
import HorizontalLayout from './horizontal.layout';
import VerticalLayout from './vertical.layout';

const sv = new AuthService();

function LayoutClient() {
  const { user, changeUser, isAdmin } = useUser();
  const { pathname } = useLocation();
  const { tenant, changeTenant } = useTenant();
  const navigate = useNavigate();

  useEffect(() => {
    Array.from(document.getElementsByClassName('ant-menu-submenu-title')).forEach((i) => {
      i.classList.remove('ant-menu-item-selected');
      // @ts-ignore
      const dataSet = i?.dataset?.menuId?.split('-');
      if (dataSet) {
        if (dataSet[dataSet.length - 1] === `/${pathname.split('/')[1]}`) {
          i.classList.add('ant-menu-item-selected');
        }
      }
    });
  }, [pathname]);

  const handleLogout = async () => {
    try {
      const refresh_token = localStorage.getItem('refreshtoken');
      refresh_token && (await sv.logout({ refresh_token }));
      changeUser(null);
      changeTenant(null);

      localStorage.removeItem('accesstoken');
      localStorage.removeItem('refreshtoken');
      localStorage.removeItem('expire');
      localStorage.removeItem('settings');
      localStorage.removeItem('tenant_lang');

      navigate(Routes.LOGIN.path);
    } catch (err) {
      console.log(err);
    }
  };
  const avartarDropdownItems = useMemo<any>(() => {
    return [
      {
        label: <p onClick={() => navigate(Routes.PROFILE.path)}>{lang.t('profile.profile_name')}</p>,
        key: 'profile',
      },
      {
        label: <p onClick={handleLogout}>{lang.t('layout.logout')}</p>,
        key: 'logout',
      },
    ];
  }, []);

  const getMenuTree = (settings: ISetting[], parent?: string, is_department?: boolean): ItemType<MenuItemType>[] => {
    return settings
      .filter((i) => {
        if (parent) {
          const arr = i.menu?.split('.') || [];
          arr.pop();
          return arr.join('.') === parent && i.is_department === is_department;
        }
        return i.menu?.split('.').length === 1;
      })
      .map((i) => {
        const path = FUNCS.renderPath(i.label);
        const children = getMenuTree(settings, i.menu, i.is_department);

        return {
          key: `/${path}`,
          label: i.is_group_menu ? (
            i.label
          ) : (
            <Link
              style={{ color: 'inherit', display: !parent ? 'block' : undefined }}
              to={path}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {i.label}
            </Link>
          ),
          icon: <IconFont type={`icon-${i.icon}`} />,
          children: children.length > 0 ? children : undefined,
          groupMenu: i.is_group_menu,
        };
      });
  };

  const showPOS = useMemo<boolean>(() => {
    const policies = user?.role.policies.map((i) => i.policy.name) || [];

    let result = false;
    ['checkout tenant maker', 'checkout department maker'].forEach((i) => {
      if (policies.includes(i)) result = true;
    });

    return result;
  }, []);

  const menus = useMemo<ItemType<MenuItemType>[]>(() => {
    if (!tenant?.settings) return [];

    return [
      ...BeforeRouteMenus.filter(
        (i) => i.path !== Routes.POS.path || (showPOS && tenant.offline && i.path === Routes.POS.path),
      ).map((i) => ({
        key: i.path,
        label: <Link to={i.path}>{i.title}</Link>,
        icon: i.icon,
      })),

      // menu lấy từ API
      // @ts-ignore
      ...getMenuTree(tenant.settings, undefined).filter((i) => !i.groupMenu || (i.children?.length || 0) !== 0),

      ...AfterRouteMenus.filter(
        (i) => i.path !== Routes.SETTING.path || (isAdmin && i.path === Routes.SETTING.path),
      ).map((i) => {
        if (i.children && i.children.length > 0) {
          if (i.path === Routes.SETTING.path) {
            if (tenant.language === 'en-US') {
              return {
                key: i.path,
                label: i.title,
                icon: i.icon,
                groupMenu: true,
                children: i.children
                  .filter((e) => e.path !== Routes.PAYMENT.path && e.path !== Routes.SOCIAL.path)
                  .map((e) => ({
                    key: e.path,
                    label: <Link to={e.path}>{e.title}</Link>,
                    icon: e.icon,
                  })),
              };
            }
          }

          return {
            key: i.path,
            label: i.title,
            icon: i.icon,
            groupMenu: true,
            children: i.children.map((e) => ({
              key: e.path,
              label: <Link to={e.path}>{e.title}</Link>,
              icon: e.icon,
            })),
          };
        }

        return {
          key: i.path,
          label: <Link to={i.path}>{i.title}</Link>,
          icon: i.icon,
        };
      }),
    ];
  }, [tenant]);

  if (!user || !tenant) return null;

  return (
    <>
      {tenant.layout === LayoutType.VERTICAL ? (
        <VerticalLayout
          logoImage={tenant.image ? FUNCS.getFullMedialUrl(tenant.image) : '/favicon/icon.png'}
          logo={tenant.name}
          accountAvatar={user?.avatar}
          menus={menus}
          accountItems={avartarDropdownItems}
          theme={tenant.theme}
          pathname={pathname}
        />
      ) : (
        <HorizontalLayout
          logoImage={FUNCS.getFullMedialUrl(tenant.image)}
          logo={tenant.name}
          accountAvatar={FUNCS.getFullMedialUrl(user?.avatar)}
          menus={menus}
          accountItems={avartarDropdownItems}
          theme={tenant.theme}
          pathname={pathname}
        />
      )}
      <ModalConfirm />
      <ExportDrawler />
      <ImportDrawler />
      <ChatWindow />
    </>
  );
}

export default memo(LayoutClient);
