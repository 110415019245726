import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { Routes } from 'src/routes';
import { IBaseProps } from 'src/types';

interface IProps extends IBaseProps {}

function CheckDeviceProvider(props: IProps) {
  const { pathname } = useLocation();
  const detectMobileOS = () => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 2;
    }

    if (/android/i.test(userAgent)) {
      return 1;
    }

    return false;
  };

  console.log('pathname: ', pathname);

  if (detectMobileOS() && pathname !== Routes.VERIFICATION.path) {
    window.location.href = `${process.env.REACT_APP_MB_URL}/#/signup`;
    return;
  }

  return props.children;
}

export default memo(CheckDeviceProvider);
