import { CreditCardOutlined, HomeOutlined, SettingOutlined, ShopOutlined, ShoppingOutlined } from '@ant-design/icons';
import lang from 'src/lang/lang';
import { IconFont } from 'src/libraries';

export interface IRoute {
  path: string;
  title: string;
  description?: string;
  permission?: string;
  icon?: any;
  children?: IRoute[];
}

export enum Route {
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  VERIFICATION = 'VERIFICATION',
  RESET_PASSWORD = 'RESET_PASSWORD',
  HOME = 'HOME',
  PRIVACY = 'PRIVACY',
  PAGE500 = 'PAGE500',
  DOCUMENT = 'DOCUMENT',
  PROFILE = 'PROFILE',

  USER = 'USER',

  POS = 'POS',

  SETTING = 'SETTING',
  GENERAL_SETTING = 'GENERAL_SETTING',
  SOCIAL = 'SOCIAL',
  LICENSE = 'LICENSE',
  PAYMENT = 'PAYMENT',
  SEPAY_GUIDE = 'SEPAY_GUIDE',

  TENANT_REGISTER = 'TENANT_REGISTER',

  DOWNLOAD = 'DOWNLOAD',
}

export const Routes: { [key in Route]: IRoute } = {
  LOGIN: {
    path: '/dang-nhap',
    title: lang.t('register.login'),
  },
  REGISTER: {
    path: '/dang-ky',
    title: lang.t('register.register'),
  },
  VERIFICATION: {
    path: '/xac-thuc-tai-khoan',
    title: lang.t('library.email_verification'),
  },
  RESET_PASSWORD: {
    path: '/dat-lai-mat-khau',
    title: lang.t('reset_password.reset_password'),
  },
  HOME: {
    path: '/',
    title: lang.t('dashboard.home_page'),
    icon: <HomeOutlined />,
  },
  PRIVACY: {
    path: '/chinh-sach',
    title: lang.t('library.policy'),
  },
  PAGE500: {
    path: '/loi',
    title: lang.t('library.sys_rerror'),
  },
  DOCUMENT: {
    path: '/tai-lieu',
    title: lang.t('task.documents'),
  },
  PROFILE: {
    path: '/thong-tin-ca-nhan',
    title: lang.t('profile.profile_name'),
  },

  POS: {
    path: '/pos',
    title: lang.t('checkout.create_fast.sold_at_the_counter'),
    icon: <ShopOutlined />,
  },

  USER: {
    path: '/thanh-vien',
    title: lang.t('default.member'),
  },

  SETTING: {
    path: '/setting',
    title: lang.t('library.setting'),
    icon: <SettingOutlined />,
  },
  GENERAL_SETTING: {
    path: '/setting-general',
    title: lang.t('setting.general_setting'),
    icon: <SettingOutlined />,
  },
  SOCIAL: {
    path: '/setting-social',
    title: lang.t('product.sales_channel'),
    icon: <ShoppingOutlined />,
  },
  LICENSE: {
    path: '/setting-license',
    title: lang.t('setting.subscription_package'),
    icon: <IconFont type="icon-two-license" />,
  },
  PAYMENT: {
    path: '/setting-payment',
    title: lang.t('library.auto_payment'),
    icon: <CreditCardOutlined />,
  },
  SEPAY_GUIDE: {
    path: '/setting-sepay-guide',
    title: lang.t('library.guide_register_sepay'),
  },

  TENANT_REGISTER: {
    path: '/dang-ky-co-so',
    title: lang.t('library.register_bussiness'),
  },

  DOWNLOAD: {
    path: '/download',
    title: 'Download',
  },
};
